import React from "react";
import {
  SettingOutlined,
  UserOutlined,
  SnippetsOutlined,
  ApartmentOutlined,
  BankOutlined,
  FormOutlined,
  MenuOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import { Menu, Button, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const MainMenu = ({ menu }) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  let items = isMobile
    ? [
        {
          key: "1",
          label: (
            <div
              style={{ fontSize: 16, width: "200px" }}
              onClick={() => navigate("/stores")}
            >
              <BankOutlined /> Stores
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              style={{ fontSize: 16, width: "200px" }}
              onClick={() => navigate("/owners")}
            >
              <UserOutlined /> Owners
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              style={{ fontSize: 16, width: "200px" }}
              onClick={() => navigate("/billing")}
            >
              <SnippetsOutlined /> Billing
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              style={{ fontSize: 16, width: "200px" }}
              onClick={() => navigate("/affiliate")}
            >
              <ApartmentOutlined /> Affiliate
            </div>
          ),
        },
        {
          key: "5",
          label: (
            <div
              style={{ fontSize: 16, width: "200px" }}
              onClick={() => navigate("/settings")}
            >
              <SettingOutlined /> Settings
            </div>
          ),
        },
        {
          key: "6",
          label: (
            <div
              style={{ fontSize: 16, width: "200px" }}
              onClick={() => navigate("/instruct")}
            >
              <FormOutlined /> Instructions
            </div>
          ),
        },
      ]
    : [
        getItem("Stores", "stores", <BankOutlined />),
        getItem("Owners", "owners", <UserOutlined />),
        getItem("Billing", "billing", <SnippetsOutlined />),
        getItem("Affiliate", "affiliate", <ApartmentOutlined />),
        getItem("Settings", "settings", <SettingOutlined />),
        getItem("Instructions", "instruct", <FormOutlined />),
      ];

  if (process.env.REACT_APP_MASTERID === "613216389261e003d696cc65") {
    items = [
      ...items,
      isMobile
        ? {
            key: "7",
            label: (
              <div
                style={{ fontSize: 16, width: "200px" }}
                onClick={() => navigate("/migrate")}
              >
                <CloudSyncOutlined /> Migration
              </div>
            ),
          }
        : getItem("Migration", "migrate", <CloudSyncOutlined />),
    ];
  }

  const onClick = (e) => {
    if (e.key === "package-a") {
    } else if (e.key === "package-b") {
    } else if (e.key === "package-c") {
    } else {
      navigate(`/${e.key}`);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            style={{
              paddingTop: 75,
              paddingLeft: 5,
            }}
          >
            <Space wrap>
              <Dropdown
                menu={{
                  items,
                }}
                placement="topLeft"
                arrow
              >
                <Button style={{ fontSize: 18, height: 40 }}>
                  <MenuOutlined /> Menu
                </Button>
              </Dropdown>
            </Space>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              width: 256,
              paddingTop: 20,
            }}
          >
            <div style={{ color: "#ffffff", margin: "0 0 20px 10px" }}>
              Hi! {user.name}
            </div>
            {menu && (
              <Menu
                defaultSelectedKeys={[menu]}
                // defaultOpenKeys={["sub1"]}
                mode="inline"
                theme="dark"
                items={items}
                onClick={onClick}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MainMenu;
