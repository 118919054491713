import React, { lazy, Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

import "react-toastify/dist/ReactToastify.css";

import Header from "./components/navigation/Header";
import MainMenu from "./components/navigation/MainMenu";

import { loginUser } from "./reducers/userSlice";
import { estoreDet } from "./reducers/estoreSlice";

const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const Payment = lazy(() => import("./pages/auth/Payment"));

const Stores = lazy(() => import("./pages/store/Stores"));
const Owners = lazy(() => import("./pages/owner/Owners"));
const Billing = lazy(() => import("./pages/billing/Billing"));
const Affiliate = lazy(() => import("./pages/affiliate/Affiliate"));
const Settings = lazy(() => import("./pages/setting/Settings"));
const Instructions = lazy(() => import("./pages/help/Instructions"));
const Training = lazy(() => import("./pages/leads/Training"));
const ResellerInfo = lazy(() => import("./pages/leads/ResellerInfo"));

const Images = lazy(() => import("./pages/migrate/Images"));

function App() {
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("userToken");

  const user = useSelector((state) => state.user);

  const [menu, setMenu] = useState("");

  useEffect(() => {
    if (userToken) {
      handleUserDetails(userToken);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUserDetails = async (token) => {
    const user = await axios.get(
      process.env.REACT_APP_API +
        "/gratis/user-details/" +
        process.env.REACT_APP_MASTERID,
      {
        headers: {
          authToken: token,
        },
      }
    );
    if (user.data.err) {
      toast.error(user.data.err);
    } else {
      if (user.data.estoreid.reseller.status) {
        localStorage.setItem("userToken", token);
        dispatch(
          loginUser({
            ...user.data,
            token,
            masterUser:
              user.data.estoreid._id === process.env.REACT_APP_MASTERID,
          })
        );
        dispatch(estoreDet(user.data.estoreid));
      } else {
        toast.error("Sorry, your login details has no reseller account.");
      }
    }
  };

  return (
    <div>
      <Header />
      {user._id ? (
        <div style={{ display: isMobile ? "block" : "flex" }}>
          <div
            style={{
              position: isMobile ? "unset" : "fixed",
              top: 60,
              height: isMobile ? "0px" : "100%",
              paddingBottom: isMobile ? "60px" : "0",
              backgroundColor: isMobile ? "" : "#000000",
            }}
          >
            <MainMenu menu={menu} />
          </div>
          <div
            style={{
              width: "100%",
              padding: isMobile ? "72px 5px 0 5px" : "72px 20px 0 265px",
            }}
          >
            <Suspense
              fallback={
                <div className="col text-center p-5">
                  <LoadingOutlined />
                </div>
              }
            >
              <Routes>
                <Route exact path="/" element={<Stores setMenu={setMenu} />} />
                <Route
                  exact
                  path="/stores"
                  element={<Stores setMenu={setMenu} />}
                />
                <Route
                  exact
                  path="/owners"
                  element={<Owners setMenu={setMenu} />}
                />
                <Route
                  exact
                  path="/billing"
                  element={<Billing setMenu={setMenu} />}
                />
                <Route
                  exact
                  path="/affiliate"
                  element={<Affiliate setMenu={setMenu} />}
                />
                <Route
                  exact
                  path="/settings"
                  element={<Settings setMenu={setMenu} />}
                />
                <Route
                  exact
                  path="/instruct"
                  element={<Instructions setMenu={setMenu} />}
                />
                <Route
                  exact
                  path="/instruct/:videoid"
                  element={<Instructions setMenu={setMenu} />}
                />
                <Route
                  exact
                  path="/migrate"
                  element={<Images setMenu={setMenu} />}
                />
              </Routes>
            </Suspense>
          </div>
        </div>
      ) : (
        <Suspense
          fallback={
            <div className="col text-center p-5">
              <LoadingOutlined />
            </div>
          }
        >
          <Routes>
            Payment
            <Route exact path="/" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/training" element={<Training />} />
            <Route exact path="/payment/:type" element={<Payment />} />
            <Route exact path="/resellerinfo" element={<ResellerInfo />} />
          </Routes>
        </Suspense>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
